
import { Component, Prop, Vue } from "vue-property-decorator";

import tigermaster from "fdtigermaster-client-sdk";

import Loading from "@/components/Utility/Loading.vue";

import {
  validateTWPhoneNum,
  validateEmail,
} from "@/models/Validator/Validator";

@Component({
  components: {
    Loading,
  },
})
export default class EnquireDispatchForm extends Vue {
  // props
  @Prop() public isLoading!: boolean;
  @Prop() public send!: any;

  // data
  companyName = "";
  userName = "";
  phone = "";
  email = "";
  demand = "";
  sourceSelected = [];
  isWarningShow = false;
  checkState: boolean[] = [true, true, true, true, true, true];

  //methods
  async createEnquireDispatch(): Promise<void> {
    const sourceStr = this.sourceSelected.join(",");
    const needToCheck = [
      this.companyName,
      this.userName,
      this.phone,
      this.email,
      this.demand,
      sourceStr,
    ];

    for (let i = 0; i < needToCheck.length; i++) {
      this.checkState[i] = Boolean(needToCheck[i]);
    }

    if (this.checkState.includes(false)) {
      this.isWarningShow = true;
      document.documentElement.scrollTop = 0;
      return;
    }

    try {
      this.isWarningShow = false;

      await tigermaster.enquire.createEnterpriseEnquire({
        demand: this.demand,
        companyName: this.companyName,
        userName: this.userName,
        phone: this.phone,
        email: this.email,
        infoSource: sourceStr,
      });
    } finally {
      this.send();
    }
  }
  validator(index: number, val: string | boolean): void {
    switch (index) {
      case 1:
        this.checkState[3] = validateTWPhoneNum(val as string);
        break;

      case 5:
        this.checkState[4] = validateEmail(val as string);
        break;

      default:
        this.checkState[index] = Boolean(val);
    }
  }
}
